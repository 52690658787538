<template>
    <v-card>
        <v-container>
             <v-row>
                <h3 class="mt-3 ml-6">REPORTES GENERALES</h3>
                <v-spacer> </v-spacer>
                </v-row>

                <v-row>
                <v-col class="pb-0">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" lg="6" md="6" class="pt-0">
                    <v-list>
                    <v-list-item>
                        <v-list-item-avatar>
                        <v-btn
                            fab
                            @click="
                            download('BALANCEGENERAL','Reporte de sucesos del Área de Selección y Calibrado')"
                            >
                            <v-icon>mdi-cloud-download</v-icon>
                            </v-btn>
                        </v-list-item-avatar>
                        <v-list-item-content>
                        <v-list-item-title
                            ><b><a @click="download('BALANCEGENERAL','Reporte de sucesos del Área de Selección y Calibrado')"
                                style="color: black">
                                Reporte de Balance de Masas</a>
                            </b>
                        </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>

    import _reportGeneral from "@/services/FreshProduction/Report/ReportFreshService";

    export default {
        data() {
            return{
                         
            }
        },

        methods: {
            download(code, name){

                if (code == "BALANCEGENERAL") {
                    _reportGeneral.massBalance({
                       
                    }, this.$fun.getUserID())
                    .then((r) => {
                        console.log("", r.data);
                    });
                }

            }
        }
    }
</script>