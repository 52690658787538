import Service from "../../Service";

const resource = "reportFresh/"

export default {
    massBalance(dtr, requestID) {

        return Service.post(resource + "massbalance", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {},
        });
    },
}